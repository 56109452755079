<template>
  <vx-card 
    :title="id === null ? $t('action.create') : $t('action.edit')"
  >

    <div class="grid sm:flex mb-2" v-if="id != null">
      <div class="w-full sm:w-10/12 break-all text-center sm:text-left flex items-center">
        <label class="mr-4 tour-publication-3">
          <b>{{ $t('formulario-da-campanha') }}</b>
        </label>
        <div>{{ shortUrl }}</div>
      </div>
      <div class="w-full sm:w-2/12 flex justify-center sm:justify-start ml-0 sm:ml-4 mt-2 sm:mt-0">
        <vs-button
            type="filled"
            icon="content_copy"
            class="footer-button"
            v-clipboard:copy="shortUrl"
            v-clipboard:success="onSucceddCopy"
            v-clipboard:error="onErrorCopy"
            >{{ $t('copiar') }}</vs-button>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          :label="$t('title')"
          v-model="title"
          name="title"
          v-validate="'required|max:191'"
        />
        <span class="text-danger text-sm" v-show="errors.has('title')">
          {{ errors.first('title') }}
        </span>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label>{{ $t('description') }}</label>
        <ComplexEditor
            v-bind:editor_data.sync="description"
            :placeholder="$t('digite-aqui')"
          ></ComplexEditor>
        <span class="text-danger text-sm" v-show="errors.has('description')">
          {{ errors.first('description') }}
        </span>
      </div>
    </div>

    <div class="vx-row mt-2">
      <div class="vx-col sm:w-1/1 w-full mb-2">
        <select-suggestion
            :max="20"
            :class="questionnaire ? 'flex-grow' : 'flex-grow required'"
            @selected-model="updateGrid"
            column="name,description"
            model="ContentQuestionnaire"
            :label="$t('Questionnaire')"
            v-model="questionnaire"
            :appendClearOption="true"
            ref="select_questionnaire"
            :placeholderText="$t('digite-o-nome-do-questionario')"
          />
        <span
          class="text-danger text-sm"
          v-show="errors.has('questionnaire_id')"
          >{{ errors.first('questionnaire_id') }}</span
        >
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <div class="pb-1 pt-1">
          
          <label>{{ $t('emails-notificacao') }}</label>
          <emails-input
            :key="leadNoticeMails"
            @tagsChanged="tagsChanged"
            :initTags="leadNoticeMails"
            :validation="[
              {
                classes: 'valid-email',
                rule: ({ text }) =>
                  !this.$utils.validation.email(text)
              }
            ]"
            :sendButton="false" >
          </emails-input>
          
          <span class="text-danger text-sm">{{ errors.first('phone') }}</span>
        </div>
        <span class="text-danger text-sm" v-show="errors.has('phone')">
          {{ errors.first('phone') }}
        </span>
      </div>
    </div>

    <div class="vx-row mt-4">
      <div class="vx-col w-full">
        <vs-button
          class="float-right"
          color="primary"
          type="border"
          @click="cancel"
          >{{ $t('action.cancel') }}</vs-button
        >
        <vs-button
          v-permission="'campaigns.create'"
          class="float-right mr-2"
          @click="create"
          :disabled="!validateForm"
          >{{ $t('action.save') }}</vs-button
        >
      </div>
    </div>

    <div v-if="id != null">
      <vs-divider class="mt-8"></vs-divider>

      <label class="font-bold">{{ $t('leads-registrados') }}</label>
      <Grid
        ref="grid"
        v-if="campaignLeadService"
        :hide_actions="true"
        :service="campaignLeadService"
        route_name="leads/grid"
        :fetchOnMounted="false"
        :column_formats="{
          created_at: (val) => {
            return $utils.format.date(val)
          },
        }"
      />
    </div>
  </vx-card>
</template>

<script>
import ComplexEditor from '@/views/apps/questionnaires/elements/ComplexEditor'
import SelectSuggestion from '@/components/SelectSuggestion.vue'
import EmailsInput from '@/components/EmailsInput'

import CampaignService from '@/services/api/CampaignService'
import CampaignLeadService from '@/services/api/CampaignLeadService'

export default {
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  components: {
    ComplexEditor,
    SelectSuggestion,
    EmailsInput
  },
  data: () => ({
    service: null,
    id: null,
    title: '',
    description: '',
    questionnaire: null,
    shortUrl: '',
    leadNoticeMails: [],
    tagsValid: false,
  }),
  computed: {
    validateForm() {
      return !this.isEmpty(this.title)
    }
  },
  methods: {
    create() {
      this.clearErrors(this.$validator)
      this.$vs.loading()


      let leadNoticeMails = ''
      let i = 0
      this.leadNoticeMails.forEach(element => {
        if (i === 0) {
          leadNoticeMails = element.text
        } else {
          leadNoticeMails = `${leadNoticeMails},${element.text}`
        }
        i++
      })

      console.log('leadNoticeMails', leadNoticeMails)

      this.service
        .createOrUpdate({
          id: this.id,
          title: this.title,
          description: this.description,
          questionnaire_id: this.questionnaire ? this.questionnaire.id : null,
          lead_notice_mails: leadNoticeMails
        })
        .then(
          data => {
            this.$vs.loading.close()
            this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
            this.$router.push(`/campaigns/${data.id}/edit`)
          },
          error => {
            this.$vs.loading.close()
            this.showErrors(this.$validator, error)
            this.notifyError(
              this.$vs,
              this.$t('nao-foi-possivel-realizar-esta-operacao')
            )
          }
        )
    },
    cancel() {
      this.$router.push('/news')
    },
    getData(id) {
      this.$vs.loading()
      this.service.read(id).then(
        data => {
          this.id = data.id
          this.title = data.title
          this.description = data.description
          this.shortUrl = data.short_url
          this.questionnaire = data.questionnaire
          this.leadNoticeMails = data.lead_notice_mails.split(',')
          console.log('get', this.leadNoticeMails)
          this.$vs.loading.close()
        },
        error => {
          this.$vs.loading.close()
        }
      )
    },
    onSucceddCopy() {
      this.notifySuccess(this.$vs, this.$t('copiado-com-sucesso'), 2000, 'center-top')
    },
    onErrorCopy() {
      this.notifyError(this.$vs, this.$t('nao-foi-possivel-copiar'))
    },
    tagsChanged(newTags, validity) {
      this.tagsValid = validity
      this.leadNoticeMails = newTags
    },
  },
  beforeMount() {
    this.service = CampaignService.build(this.$vs)
    this.campaignLeadService = CampaignLeadService.build(this.$vs)
  },
  mounted() {
    if (this.id) {
      this.getData(this.id)

      const gridRef = this.$refs['grid']
      if (gridRef) {
        gridRef.fillCustomFilters([{key: 'campaign_id', value: this.id || ''}])
        gridRef.fetchGridData()
      }
    }
  }
}
</script>
